import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";
import Fade from "react-reveal";
import { Container } from "react-bootstrap";
import Header from "./Header";
import endpoints from "../constants/endpoints";
import FallbackSpinner from "./FallbackSpinner";

const styles = {
  iconStyle: {
    width: 75,
    margin: 10,
    marginBottom: 0,
  },
  introTextContainer: {
    whiteSpace: "pre-wrap",
  },
  revertImg: {
    filter: "invert(100%)",
  },
};

function Skills(props) {
  const { header, darkMode } = props;
  const [data, setData] = useState(null);

  const renderSkillsIntro = (intro) => (
    <h4 style={styles.introTextContainer}>
      <ReactMarkdown children={intro} />
    </h4>
  );

  useEffect(() => {
    fetch(endpoints.skills, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => setData(res))
      .catch((err) => err);
  }, []);

  return (
    <>
      <Header title={header} />
      {data ? (
        <Fade>
          <div className="section-content-container">
            <Container>
              {renderSkillsIntro(data.intro)}
              {data.skills?.map((rows) => (
                <div key={rows.title}>
                  <br />
                  <h3>{rows.title}</h3>
                  {rows.items.map((item) => (
                    <div key={item.title} style={{ display: "inline-block" }}>
                      {darkMode.value ? (
                        item.darkModeIcon ? (
                          <img
                            style={styles.iconStyle}
                            src={item.darkModeIcon}
                            alt={item.title}
                          />
                        ) : item.darkModeRevert ? (
                          <img
                            style={Object.assign(
                              {},
                              styles.iconStyle,
                              styles.revertImg
                            )}
                            src={item.icon}
                            alt={item.title}
                          />
                        ) : (
                          <img
                            style={styles.iconStyle}
                            src={item.icon}
                            alt={item.title}
                          />
                        )
                      ) : item.lightModeRevert ? (
                        <img
                        style={Object.assign(
                          {},
                          styles.iconStyle,
                          styles.revertImg
                        )}
                          src={item.icon}
                          alt={item.title}
                        />
                      ) : (
                        <img
                          style={styles.iconStyle}
                          src={item.icon}
                          alt={item.title}
                        />
                      )}

                      <p>{item.title}</p>
                    </div>
                  ))}
                </div>
              ))}
            </Container>
          </div>
        </Fade>
      ) : (
        <FallbackSpinner />
      )}
    </>
  );
}

Skills.propTypes = {
  header: PropTypes.string.isRequired,
};

export default Skills;
