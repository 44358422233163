import React from "react";
import { Switch, Route } from "react-router-dom";
import NavBarWithRouter from "./components/NavBar";
import Home from "./components/Home";
import NotFound from "./components/NotFound";
import About from "./components/About";
import Skills from "./components/Skills";
import Education from "./components/Education";
import Projects from "./components/Projects";
import Experience from "./components/Experience";
import Contact from "./components/Contact";
function MainApp({ darkMode }) {
  return (
    <div className="MainApp">
      <NavBarWithRouter />
      <main className="main">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route
            exact
            key="Contact"
            path="/contact"
            component={() => (
              <Contact header="Contact Me" darkMode={darkMode} />
            )}
          />
          <Route
            exact
            key="About"
            path="/about"
            component={() => <About header="About" />}
          />
          <Route
            exact
            key="Skills"
            path="/skills"
            component={() => <Skills header="Skills" darkMode={darkMode} />}
          />
          <Route
            exact
            key="Education"
            path="/education"
            component={() => <Education header="Education" />}
          />
          <Route
            exact
            key="Experience"
            path="/experience"
            component={() => <Experience header="Experience" />}
          />
          <Route
            exact
            key="Projects"
            path="/projects"
            component={() => <Projects header="Projects" />}
          />
          <Route component={NotFound} status={404} />
        </Switch>
      </main>
    </div>
  );
}

export default MainApp;
