import React, { useEffect, useState } from "react";
import Header from "./Header";
import "../css/contact.css";
import { useForm } from "@formspree/react";

function Contact(props) {
  const { header, darkMode } = props;
  const [width, setWidth] = useState("50vw");
  const [state, handleSubmit] = useForm("xnqkpako");

  useEffect(() => {
    if (window?.innerWidth < 576) {
      setWidth("90vw");
    } else if (window?.innerWidth >= 576 && window?.innerWidth < 768) {
      setWidth("90vw");
    } else if (window?.innerWidth >= 768 && window?.innerWidth < 1024) {
      setWidth("75vw");
    } else {
      setWidth("50vw");
    }
  }, []);

  const darkForm = `
  :root {
    --white: #afafaf;
    --red: #e31b23;
    --bodyColor: #292a2b;
    --borderFormEls: hsl(0, 0%, 10%);
    --bgFormEls: hsl(0, 0%, 14%);
    --bgFormElsFocus: hsl(0, 7%, 20%);
  }
  .formBody {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 1.5rem;
  }`;
  const lightForm = `
  :root {
    --red: #e31b23;
    --bodyColor: #292a2b;
    --borderFormEls: hsl(0, 0%, 10%);
    --bgFormElsFocus: #e8e8e8;
  }
  .formBody {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 1.5rem;
  }`;

  if (state.succeeded) {
    return (
      <>
        <Header title={header} />
        <p>Thanks for your connection!</p>
      </>
    );
  }

  return (
    <>
      {darkMode.value ? <style>{darkForm}</style> : <style>{lightForm}</style>}
      <Header title={header} />
      <form onSubmit={handleSubmit} className="my-form" style={{ width }}>
        <div className="formBody">
          <ul>
            <li>
              <div className="grid grid-2">
                <input
                  type="text"
                  id="fname"
                  name="firstname"
                  placeholder="First Name"
                />
                <input
                  type="text"
                  id="lname"
                  name="lastname"
                  placeholder="Last Name"
                />
              </div>
            </li>
            <li>
              <div className="grid grid-1">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  required
                />
              </div>
            </li>
            <li>
              <textarea
                id="message"
                name="message"
                placeholder="Message"
                required
              ></textarea>
            </li>
            <li>
              <div className="grid grid-3">
                <div />
                <button className="btn-grid" type="submit">
                  <span className="back">
                    <img
                      src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/email-icon.svg"
                      alt=""
                    />
                  </span>
                  <span className="front" disabled={state.submitting}>
                    SUBMIT
                  </span>
                </button>
                <button className="btn-grid" type="reset">
                  <span className="back">
                    <img
                      src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/eraser-icon.svg"
                      alt=""
                    />
                  </span>
                  <span className="front" disabled={state.submitting}>
                    RESET
                  </span>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </form>
    </>
  );
}

export default Contact;
