import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";

const NotFound = () => {
  const theme = useContext(ThemeContext);

  return (
    <div>
      <h1>404. That’s an error.</h1>
      <p>The requested URL was not found on this server.</p>
      <Link to="/">
        <Button variant={"outline-" + theme.bsSecondaryVariant}>
          Back to Home
        </Button>
      </Link>
    </div>
  );
};

export default NotFound;
